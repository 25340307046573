@layer components {
    // Common
    .mat-icon-20 {
        @apply h-[20px] w-[20px] #{!important};
        @apply text-[20px] #{!important};
    }

    .mat-icon-40 {
        @apply h-[40px] w-[40px] #{!important};
        @apply text-[40px] #{!important};
    }
}
