ion-content {
    --background: var(--black-95);
}

ion-item {
    --background: transparent;
    color: white;
}

ion-list {
    background-color: transparent !important;
}

ion-range {
    --knob-background: var(--turtle-cyan-80);
    --pin-background: var(--turtle-cyan-100);
    --bar-background: var(--turtle-cyan-30);
    --bar-background-active: var(--turtle-cyan-60);
}

ion-select {
}
