:root {
    //--main-bg-color: #19FFE6;

    --turtle-cyan-100: #19ffe6;
    --turtle-cyan-90: #17e6cf;
    --turtle-cyan-80: #14ccb7;
    --turtle-cyan-70: #12b3a1;
    --turtle-cyan-60: #0f998a;
    --turtle-cyan-50: #0d8073;
    --turtle-cyan-40: #0a665c;
    --turtle-cyan-30: #084d45;
    --turtle-cyan-20: #05332e;
    --turtle-cyan-10: #031917;

    --turtle-yellow-100: #fff24d;
    --turtle-yellow-90: #e6da45;
    --turtle-yellow-80: #ccc23d;
    --turtle-yellow-70: #b3aa36;
    --turtle-yellow-60: #99912e;
    --turtle-yellow-50: #807926;
    --turtle-yellow-40: #66611f;
    --turtle-yellow-30: #4d4917;
    --turtle-yellow-20: #33300f;
    --turtle-yellow-10: #191808;

    --black-100: #000000;
    --black-95: #0d0d0d;
    --black-90: #191919;
    --black-85: #262626;
    --black-80: #333333;
    --black-75: #404040;
    --black-70: #4d4d4d;
    --black-65: #595959;
    --black-60: #666666;
    --black-55: #737373;
    --black-50: #808080;
    --black-45: #8c8c8c;
    --black-40: #999999;
    --black-35: #a6a6a6;
    --black-30: #b3b3b3;
    --black-25: #bfbfbf;
    --black-20: #cccccc;
    --black-15: #d9d9d9;
    --black-10: #e6e6e6;
    --black-5: #f2f2f2;
    --black-0: #ffffff;

    --turtle-red-100: #7f1d1d;
    --turtle-red-90: #991b1b;
    --turtle-red-80: #b91c1c;
    --turtle-red-70: #dc2626;
    --turtle-red-60: #ef4444;
    --turtle-red-50: #f87171;
    --turtle-red-40: #fca5a5;
    --turtle-red-30: #fecaca;
    --turtle-red-20: #fee2e2;
    --turtle-red-10: #fef2f2;
}

//$primary-color: var(--turtle-cyan-100);
//$secondary-color: var(--turtle-cyan-30);
//$tertiary-color: var(--turtle-cyan-10);
