@layer components {
    .button {
        @apply relative flex cursor-pointer select-none border bg-black-80 p-1 text-base text-white md:p-4;

        &.ripple {
            &:after {
                content: '';
                @apply absolute left-0 top-0 block h-full w-full opacity-0 shadow-[0_0_10px_40px_white];

                // animation
                @apply transition-all duration-500;
            }

            &:active:after {
                @apply absolute left-0 top-0 opacity-100 shadow-[0_0_0_0_white];

                // animation
                @apply duration-[0s];
            }
        }

        @mixin rippleWithBorder($name, $borderRadius) {
            &.ripple-#{$name} {
                &:after {
                    content: '';
                    @apply absolute left-0 top-0 block h-full w-full opacity-0 shadow-[0_0_10px_40px_white];
                    border-radius: #{$borderRadius};

                    // animation
                    @apply transition-all duration-500;
                }

                &:active:after {
                    @apply absolute left-0 top-0 opacity-100 shadow-[0_0_0_0_white];
                    border-radius: #{$borderRadius};

                    // animation
                    @apply duration-[0s];
                }
            }
        }

        @include rippleWithBorder('0', '0rem');
        @include rippleWithBorder('1', '1rem');
        @include rippleWithBorder('2', '2rem');
        @include rippleWithBorder('3', '3rem');
        @include rippleWithBorder('4', '4rem');

        &.touched {
            background-color: green !important;
            transition: background-color ease;
            transition-duration: 100ms;
        }
    }
}
