@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'variables';

@import 'scss/utils';

@import 'scss/ion/custom-elements';

/*
    Add elements creating custom tailwind classes (using @apply) here.
 */
@import 'scss/tailwind/button';
@import 'scss/tailwind/mat-icon';
@import 'scss/tailwind/track-pattern';

/* locally added sound fonts */
@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Material Icons';
    src: url('assets/fonts/MaterialIcons-Regular.ttf');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
ion-toast {
    margin-top: -50px; // workaround to fix ion-toasts a little bit over their bottom position
}
ion-range {
    --bar-height: 8px;
    --bar-border-radius: 8px;
    --knob-size: 16px;
}
ion-select {
    --padding-start: 4px;
}
