@layer components {
    // Track Pattern
    .track-pattern__status {
        @apply absolute right-[5px] z-workspaceOverlay m-[5px] flex h-[30px] w-[30px] cursor-pointer select-none items-center justify-center rounded-[50%] bg-[#cedc16];
    }

    .track-pattern__action {
        @apply z-workspaceOverlay m-[5px] flex h-[60px] w-[60px] cursor-pointer select-none items-center justify-center rounded-[50%] bg-[white];
    }
}
